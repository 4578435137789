// モジュール読み込み
// ============================================================

//import Vue from 'vue';
import * as func from './functions/_function';                        //関数関係
import * as animation from './functions/_animation';                  //アニメーション関係
import * as bliderBox from './functions/_bliderBox'                 //ブラインダーボックス
import * as offcanvas from './functions/_offcanvas';                  //オフキャンバス
import * as pagetop from './functions/_pagetop';                    //ページトップ
import * as loading from './functions/_loading';                      //ローディング
//import * as smoothScroll from './functions/_smoothScroll';          //スムーススクロール
import Modal from '../../node_modules/bootstrap/js/dist/modal';     //モーダルライブラリ
import 'mobile-detect';                                               //デバイス判定・ブラウザ判定ライブラリ
import * as checkUA from './functions/_checkUA';                      //デバイス判定・ブラウザ判定処理
import 'slick-carousel';                                              //slick


// 定数
// ============================================================

const BLAKEPOINT_HEADER_SP_MODE = 2000;                               //ヘッダーレスポンシブブレイクポイント
const DEBUG_MODE                = false;                              //デバッグモード

// グローバル変数
// ============================================================

let CURRENT_SCROLL_POS = 0;
let WIN_WIDTH                 = window.innerWidth;
let WIN_HEIGHT                = window.innerHeight;
let CURRENT_SC_OFFSET_X       = window.pageXOffset;
let CURRENT_SC_OFFSET_Y       = window.pageYOffset;
let SCROLLDOWN_FLAG           = true;

// サイト共通関数初期化
// ============================================================

animation.scrollObserver();                                              // アニメーション処理
checkUA.addUAClass();// UAおよびデバイスクラス追加処理
loading.initLoading(DEBUG_MODE);
//headerFixed.setHeaderFixed();                                          // ヘッダー固定
//smoothScroll.setSmoothScroll();                                        // スムーススクロール
bliderBox.setBliderBox();                                              // ブラインダーボックス初期化
offcanvas.setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,'app');  // オフキャンバス処理

// イベント毎のサイト共通関数初期化
// ============================================================

window.addEventListener('scroll', () => {
  CURRENT_SC_OFFSET_X = window.pageXOffset;
  CURRENT_SC_OFFSET_Y = window.pageYOffset;
  
  //引数：固定,固定,ボタンを出現させるきっかけとなる要素のID名,ボタンを固定させるきっかけとなる要素のID名
  //pagetop.setScrolltopBtn(WIN_HEIGHT,CURRENT_SC_OFFSET_Y,'mv','footer');
  
})

window.addEventListener('resize', () => {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
})

// ============================================================



